import {gql, useMutation} from '@apollo/client'

const UPDATE_RESUME_EVENT = gql`
  mutation UpdateResumeBuilderEvent(
    $eventType: String!
    $completions: Int
    $referral: String
  ) {
    updateResumeBuilderEvent(
      eventType: $eventType
      completions: $completions
      referral: $referral
    ) {
      event {
        id
        start_time
        complete_time
        completions
        referral
      }
      errors
    }
  }
`

export const useResumeEvent = () => {
  const [updateEvent] = useMutation(UPDATE_RESUME_EVENT)

  const trackStart = async (referral?: string) => {
    await updateEvent({
      variables: {eventType: 'start', referral},
    })
  }

  const trackComplete = async (completions: number) => {
    await updateEvent({
      variables: {eventType: 'complete', completions},
    })
  }

  return {trackStart, trackComplete}
}
